import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Box,
} from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BusinessIcon from '@mui/icons-material/Business';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import { ReactComponent as Logo } from '../assets/logo.svg';

const drawerWidth = 240;

interface NavItem {
  name: string;
  path: string;
  icon: React.ReactElement;
}

const mainNavItems: NavItem[] = [
  { name: 'Dashboard', path: '/dashboard', icon: <DashboardIcon /> },
  { name: 'My OKRs', path: '/my-okrs', icon: <AssignmentIcon /> },
  {
    name: 'OKR Relationships',
    path: '/okr-relationships',
    icon: <BusinessIcon />,
  },
];

const bottomNavItems: NavItem[] = [
];

interface NavbarProps {
  logout: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ logout }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/login');
    window.location.reload();
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: '#212121',
          color: '#ffffff',
          position: 'fixed',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {/* Logo Section */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '64px',
            p: 2,
          }}
        >
          <Logo style={{ height: '40px' }} />
        </Box>
        
        <Divider />

        {/* Main Navigation Items */}
        <List>
          {mainNavItems.map((item) => (
            <Link
              to={item.path}
              key={item.name}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <ListItem
                button
                sx={{
                  backgroundColor:
                    location.pathname === item.path ? '#424242' : 'inherit',
                  '&:hover': {
                    backgroundColor: '#333333',
                  },
                }}
              >
                <ListItemIcon sx={{ color: '#ffffff' }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText 
                  primary={item.name}
                  primaryTypographyProps={{
                    style: { color: '#ffffff' },
                  }}
                />
              </ListItem>
            </Link>
          ))}
        </List>

        {/* Spacer to push bottom items down */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Bottom Items (Settings and Logout) */}
        <Box>
          <Divider />
          <List>
            {/* Settings */}
            {bottomNavItems.map((item) => (
              <Link
                to={item.path}
                key={item.name}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <ListItem
                  button
                  sx={{
                    backgroundColor:
                      location.pathname === item.path ? '#424242' : 'inherit',
                    '&:hover': {
                      backgroundColor: '#333333',
                    },
                  }}
                >
                  <ListItemIcon sx={{ color: '#ffffff' }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText 
                    primary={item.name}
                    primaryTypographyProps={{
                      style: { color: '#ffffff' },
                    }}
                  />
                </ListItem>
              </Link>
            ))}
            {/* Logout */}
            <ListItem
              button
              onClick={handleLogout}
              sx={{
                '&:hover': {
                  backgroundColor: '#333333',
                },
              }}
            >
              <ListItemIcon sx={{ color: '#ffffff' }}>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Logout"
                primaryTypographyProps={{
                  style: { color: '#ffffff' },
                }}
              />
            </ListItem>
          </List>
        </Box>
      </Box>
    </Drawer>
  );
};

export default Navbar;