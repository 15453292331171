import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Divider,
  Alert,
  CircularProgress
} from '@mui/material';
import axios from 'axios';
import supabase from '../Auth/supabase';
import { useLocation } from 'react-router-dom';  // Add this import

interface LinearOrganization {
  id: string;
  name: string;
}

interface LinearStatus {
  connected: boolean;
  organization?: LinearOrganization;
  lastUpdated?: string;
}

const Settings = () => {
  const [linearStatus, setLinearStatus] = useState<LinearStatus>({ connected: false });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const linearResult = params.get('linear');
    
    if (linearResult === 'success') {
      setError(null);
      checkLinearStatus();
    } else if (linearResult === 'error') {
      setError('Failed to connect Linear. Please try again.');
    }
  }, [location]);

  useEffect(() => {
    checkLinearStatus();
  }, []);

  const checkLinearStatus = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session?.access_token) {
        throw new Error('No authentication token found');
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/integrations/linear/status`,
        {
          headers: {
            Authorization: `Bearer ${session.access_token}`
          }
        }
      );
      
      setLinearStatus(response.data);
    } catch (err: any) {
      console.error('Linear status check error:', err);
      setError('Failed to check Linear integration status');
    } finally {
      setLoading(false);
    }
  };

  const handleLinearConnect = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session?.access_token) {
        throw new Error('No authentication token found');
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/integrations/linear/connect`,
        {
          headers: {
            Authorization: `Bearer ${session.access_token}`
          }
        }
      );

      if (response.data.authUrl) {
        window.location.href = response.data.authUrl;
      } else {
        setError('No authorization URL received');
      }
    } catch (err: any) {
      console.error('Linear connect error:', err);
      setError('Failed to initiate Linear connection');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Settings
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Card sx={{ maxWidth: 600, mb: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Linear Integration
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            Connect your Linear account to sync issues with OKRs
          </Typography>
          
          {linearStatus.connected && linearStatus.organization && (
            <>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body2" color="text.secondary">
                Connected to: {linearStatus.organization.name}
              </Typography>
              {linearStatus.lastUpdated && (
                <Typography variant="body2" color="text.secondary">
                  Last updated: {new Date(linearStatus.lastUpdated).toLocaleString()}
                </Typography>
              )}
            </>
          )}
        </CardContent>
        <Divider />
        <CardActions>
          {linearStatus.connected ? (
            <Button disabled variant="contained" color="success">
              Connected to Linear
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={handleLinearConnect}>
              Connect Linear
            </Button>
          )}
        </CardActions>
      </Card>
    </Box>
  );
};

export default Settings;