import React, { useState, useRef } from 'react';
import slackUpdateVideo from '../../assets/slack-update-video.mp4';
import noChasingVideo from '../../assets/no-more-chasing-video.mp4';
import simpleInputsVideo from '../../assets/simple-inputs-video.mp4';
import OKRTreeImage from '../../assets/OKRTree.png';

interface Feature {
  media: string;
  type: 'video' | 'image';
  title: string;
  description: string;
}

const HowSection: React.FC = () => {
  const [currentMedia, setCurrentMedia] = useState<Feature>(null!);
  const [selectedFeature, setSelectedFeature] = useState<number>(0);
  const videoRef = useRef<HTMLVideoElement>(null);

  const features: Feature[] = [
    {
      media: slackUpdateVideo,
      type: 'video',
      title: "Updating is as easy as sending a slack",
      description: "Setting, updating and tracking all happens in your existing workflow"
    },
    {
      media: noChasingVideo,
      type: 'video',
      title: "No more chasing for updates",
      description: "Automated Slack notifications remind team members to update OKRs"
    },
    {
      media: simpleInputsVideo,
      type: 'video',
      title: "Simple inputs but powerful outputs",
      description: "Dashboard that summarizes performance across teams"
    },
    {
      media: OKRTreeImage,
      type: 'image',
      title: "See why you are doing what you are doing",
      description: "Visibility of how each individual OKR is contributing towards the company goal"
    }
  ];

  // Set initial feature on component mount
  React.useEffect(() => {
    setCurrentMedia(features[0]);
  }, []);

  const handleClick = (feature: Feature, index: number) => {
    setCurrentMedia(feature);
    setSelectedFeature(index);
    if (feature.type === 'video' && videoRef.current) {
      videoRef.current.play();
    }
  };

  return (
    <div className="tw-py-24 tw-bg-white">
      <div className="tw-max-w-7xl tw-mx-auto tw-px-6 lg:tw-px-8 tw-flex tw-flex-col lg:tw-flex-row tw-items-start tw-gap-16">
        {/* Text Content */}
        <div className="tw-w-full lg:tw-w-1/2 tw-space-y-8">
          <h2 className="tw-text-3xl tw-font-bold tw-tracking-tight tw-text-gray-900">
            The How
          </h2>
          
          {features.map((feature, index) => (
            <div
              key={index}
              onClick={() => handleClick(feature, index)}
              className={`tw-rounded-2xl tw-p-4 tw-cursor-pointer tw-transition-all tw-duration-300 ${
                selectedFeature === index 
                  ? 'tw-bg-indigo-100 tw-transform tw-scale-105' 
                  : 'tw-bg-gray-50 tw-hover:bg-gray-100'
              }`}
            >
              <h3 className={`tw-text-xl tw-font-bold tw-mb-2 ${
                selectedFeature === index ? 'tw-text-indigo-600' : 'tw-text-gray-900'
              }`}>
                {feature.title}
              </h3>
              <p className="tw-text-gray-600">
                {feature.description}
              </p>
            </div>
          ))}
        </div>

        {/* Media Content */}
        <div className="tw-w-full lg:tw-w-1/2 tw-mt-16 lg:tw-mt-30">
          <div className="tw-rounded-2xl tw-bg-gray-50 tw-p-4">
            {currentMedia && currentMedia.type === 'video' ? (
              <video
                ref={videoRef}
                src={currentMedia.media}
                controls={false}
                autoPlay
                loop
                muted
                className="tw-w-full tw-rounded-xl tw-shadow-lg"
              />
            ) : (
              <img
                src={currentMedia?.media}
                alt="OKR Tree visualization"
                className="tw-w-full tw-rounded-xl tw-shadow-lg"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowSection;