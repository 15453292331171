// src/pages/Dashboard.tsx

import React, { useState, useEffect } from 'react';
import {
  Tabs,
  Tab,
  Divider,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Dialog,
  Link,
  Card,
  CardContent,
  DialogTitle,
  DialogContent,
  DialogActions,
  List, 
  Chip,
  ListItem,
  ListItemText,
  Paper,
  Button,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  LocalizationProvider,
  DatePicker,
} from '@mui/x-date-pickers';
import axios from 'axios';
import supabase from '../Auth/supabase';
import { MainCardsLayout } from '../components/MainCards';
import FocusOKR from './FocusOKR';
import PreviousOKR from './PreviousOKR';
import { OKR } from '../types';
import { useLocation, useNavigate } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/Link';  
import { format } from 'date-fns';  


interface Team {
  name: string;
}

interface User {
  name: string;
  assigneeId: string;
}

interface LinearIssue {
  id: string;
  title: string;
  state: string;
  url: string;
  team?: string;
  createdAt: string;
}

interface Comment {
  text: string;
  author: string;
  source: 'Linear' | 'OnTrakk';
  timestamp: string;
}

const Dashboard: React.FC = () => {
  // State for tabs
  const [selectedTab, setSelectedTab] = useState<number>(0);
  

  // State for OKRs and filters
  const [okrs, setOkrs] = useState<OKR[]>([]);
  const [filterType, setFilterType] = useState<string>('All');
  const [teams, setTeams] = useState<Team[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [timeFilterType, setTimeFilterType] = useState<string>('All');
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [newComment, setNewComment] = useState('');
  const [currentUserName, setCurrentUserName] = useState<string>('User');


  // State for modal dialog
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedOKR, setSelectedOKR] = useState<OKR | null>(null);
  const [companyOkrs, setCompanyOkrs] = useState<OKR[]>([]);
  const [teamOkrs, setTeamOkrs] = useState<OKR[]>([]);


  // URL params
  const location = useLocation();
  const navigate = useNavigate();

  // Linear integration states
  const [linearIssues, setLinearIssues] = useState<LinearIssue[]>([]);
  const [selectedLinearIssue, setSelectedLinearIssue] = useState<string>('');
  const [loadingLinearIssues, setLoadingLinearIssues] = useState(false);
  const [hasLinearIntegration, setHasLinearIntegration] = useState(false);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://ontrakk.com';


  // Function to fetch OKRs
  const fetchOKRs = async () => {
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      const token = session?.access_token;

      if (token) {
        const response = await axios.get<OKR[]>(
          `${process.env.REACT_APP_API_BASE_URL}/api/okrs`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setOkrs(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch OKRs:', error);
    }
  };

  // Function to fetch teams and users
  const fetchTeamsAndUsers = async () => {
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      const token = session?.access_token;

      if (token) {
        const teamsResponse = await axios.get<Team[]>(
          `${process.env.REACT_APP_API_BASE_URL}/api/teams`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTeams(teamsResponse.data);

        const usersResponse = await axios.get<User[]>(
          `${process.env.REACT_APP_API_BASE_URL}/api/okr-owners`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(usersResponse.data);
      }
    } catch (error) {
      console.error('Failed to fetch teams or users:', error);
    }
  };

// Function to fetch OKRs, company OKRs, and team OKRs
const fetchOKRsAndParents = async () => {
  try {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    const token = session?.access_token;

    if (token) {
      // Fetch all OKRs
      const response = await axios.get<OKR[]>(
        `${process.env.REACT_APP_API_BASE_URL}/api/okrs?role=all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const allOkrs = response.data.filter(
        (okr) => okr.Status !== 'Completed' && okr.Status !== 'Failed'
      );
      setOkrs(allOkrs);

      // Fetch company OKRs
      const companyOkrsResponse = await axios.get<OKR[]>(
        `${process.env.REACT_APP_API_BASE_URL}/api/okrs?goalType=Company&role=all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const companyOkrs = companyOkrsResponse.data.filter(
        (okr) => okr.Status !== 'Completed' && okr.Status !== 'Failed'
      );
      setCompanyOkrs(companyOkrs);

      // Fetch team OKRs for the user's team
      const teamOkrsResponse = await axios.get<OKR[]>(
        `${process.env.REACT_APP_API_BASE_URL}/api/okrs?goalType=Team&role=all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const teamOkrs = teamOkrsResponse.data.filter(
        (okr) => okr.Status !== 'Completed' && okr.Status !== 'Failed'
      );
      setTeamOkrs(teamOkrs);
    }
  } catch (error) {
    console.error('Failed to fetch OKRs:', error);
  }
};

useEffect(() => {
  const fetchUserName = async () => {
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      const token = session?.access_token;
      const userEmail = session?.user?.email;

      if (token && userEmail) {
        // Same approach as in TopBar
        const response = await axios.get(`${apiBaseUrl}/api/okr-owners`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        });

        const owners = response.data;
        const currentUser = owners.find((owner: any) => owner.email === userEmail);

        if (currentUser?.name) {
          setCurrentUserName(currentUser.name);
        } else {
          setCurrentUserName('User');
        }
      }
    } catch (error) {
      console.error('Failed to fetch user name:', error);
      setCurrentUserName('User');
    }
  };

  fetchUserName();
}, []);

// Checking for Linear integration
const checkLinearIntegration = async () => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    
    if (!session?.access_token) {
      return;
    }

    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/integrations/linear/status`,
      {
        headers: {
          Authorization: `Bearer ${session.access_token}`
        }
      }
    );
    
    setHasLinearIntegration(response.data.connected);
  } catch (error) {
    console.error('Failed to check Linear integration:', error);
    setHasLinearIntegration(false);
  }
};

// Fetch Linear issues
const fetchLinearIssues = async () => {
  try {
    setLoadingLinearIssues(true);
    const { data: { session } } = await supabase.auth.getSession();
    
    if (!session?.access_token) {
      throw new Error('No authentication token found');
    }

    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/company/linear/issues`,
      {
        headers: {
          Authorization: `Bearer ${session.access_token}`
        }
      }
    );
    
    setLinearIssues(response.data);
  } catch (error) {
    console.error('Failed to fetch Linear issues:', error);
  } finally {
    setLoadingLinearIssues(false);
  }
};

// Add this function to link an OKR to a Linear issue
const handleLinkLinearIssue = async () => {
  if (!selectedOKR?._id || !selectedLinearIssue) return;

  try {
    const { data: { session } } = await supabase.auth.getSession();
    
    if (!session?.access_token) {
      throw new Error('No authentication token found');
    }

    // Find the linked issue details before making the request
    const linkedIssue = linearIssues.find(issue => issue.id === selectedLinearIssue);
    
    // Make the link request
    await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/okrs/${selectedOKR._id}/linear/link`,
      { linearIssueId: selectedLinearIssue },
      {
        headers: {
          Authorization: `Bearer ${session.access_token}`
        }
      }
    );

    // Update the OKRs in the background
    fetchOKRs();

    // Update the selected OKR state
    setSelectedOKR(prevOKR => ({
      ...prevOKR!,
      linearIssueId: linkedIssue?.id,
      linearIssueUrl: linkedIssue?.url,
      linearIssueTitle: linkedIssue?.title
    }));

    // Clear the selected Linear issue
    setSelectedLinearIssue('');

  } catch (error) {
    console.error('Failed to link Linear issue:', error);
    alert('Failed to link Linear issue. Please try again.');
  }
};

useEffect(() => {
  const searchParams = new URLSearchParams(location.search);
  const okrId = searchParams.get('okr');

  if (okrId && okrs.length > 0) {
    const okr = okrs.find(o => o._id === okrId);
    if (okr) {
      setSelectedOKR(okr);
      setOpenModal(true);
    }
  }
}, [location.search, okrs]);


  // Initial data fetch
    useEffect(() => {
      fetchOKRs();
      fetchTeamsAndUsers();
      fetchOKRsAndParents();
      checkLinearIntegration();  
    }, []);



    // Handler for tab change
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
      setSelectedTab(newValue);
    };

  // Handler to open the modal with selected OKR
  const handleOKRClick = (okr: OKR) => {
    setSelectedOKR(okr);
    setOpenModal(true);
    navigate(`/dashboard?okr=${okr._id}`);
  };

    // Handler to close the modal
    const handleCloseModal = () => {
      setOpenModal(false);
      setSelectedOKR(null);
      navigate('/dashboard');
    };

    // Handler to save the updated OKR
    const handleSaveOKR = async () => {
      try {
        const {
          data: { session },
        } = await supabase.auth.getSession();
        const token = session?.access_token;
    
        if (token && selectedOKR) {
          const { _id, ...okrData } = selectedOKR;

              // 1) If the user typed a new comment, build it:
              let updatedOKR = { ...selectedOKR };
              if (newComment.trim()) {
                const comment: Comment = {
                 text: newComment.trim(),
                 author: currentUserName, 
                  source: 'OnTrakk',
                  timestamp: new Date().toISOString(),
                };
                updatedOKR = {
                  ...updatedOKR,
                  comments: [...(updatedOKR.comments || []), comment],
                };
              }  
              const updateData = {
                ...okrData,
                comments: updatedOKR.comments,
              };
              
          
          if (_id) {
            // Update existing OKR
            await axios.put(
              `${process.env.REACT_APP_API_BASE_URL}/api/okrs/${_id}`,
              updateData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } else {
            // Create new OKR
            await axios.post(
              `${process.env.REACT_APP_API_BASE_URL}/api/okrs`,
              updateData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }
    
          setSelectedOKR(updatedOKR);
          setNewComment('');
          // Refresh OKRs after update
          await fetchOKRs();
          handleCloseModal();
        }
      } catch (error) {
        console.error('Failed to save OKR:', error);
        alert('Failed to save OKR. Please try again.');
      }
    };




    return (
      <Box sx={{ flexGrow: 1, padding: '20px' }}>
        {/* Tabs */}
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Overview" />
          <Tab label="OKRs to focus on" />
          <Tab label="Previous OKRs" />
        </Tabs>
   
        {/* Tab Panels */}
        {selectedTab === 0 && (
  <Box sx={{ mt: 2 }}>
    {/* Filter Section with Add OKR button */}
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center', 
      mb: 3 
    }}>
      {/* Filter controls in their own container */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography variant="body1" sx={{ mr: 1 }}>
          Filter by:
        </Typography>
        
        <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
          <InputLabel>Type</InputLabel>
          <Select
            value={filterType}
            onChange={(e) => {
              setFilterType(e.target.value);
              setSelectedTeam('');
              setSelectedUser('');
            }}
            label="Type"
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Company">Company</MenuItem>
            <MenuItem value="Team">Team</MenuItem>
            <MenuItem value="Individual">Individual</MenuItem>
          </Select>
        </FormControl>

        {filterType === 'Team' && (
          <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
            <InputLabel>Select Team</InputLabel>
            <Select
              value={selectedTeam}
              onChange={(e) => setSelectedTeam(e.target.value)}
              label="Select Team"
            >
              {teams.map((team, index) => (
                <MenuItem key={index} value={team.name}>
                  {team.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {filterType === 'Individual' && (
          <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
            <InputLabel>Select User</InputLabel>
            <Select
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
              label="Select User"
            >
              {users.map((user, index) => (
                <MenuItem key={index} value={user.assigneeId}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
          <InputLabel>Time Period</InputLabel>
          <Select
            value={timeFilterType}
            onChange={(e) => {
              setTimeFilterType(e.target.value);
              if (e.target.value === 'All') {
                setFromDate(null);
                setToDate(null);
              }
            }}
            label="Time Period"
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Custom">Custom</MenuItem>
          </Select>
        </FormControl>

        {timeFilterType === 'Custom' && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="From"
              value={fromDate}
              onChange={(newValue: Date | null) => setFromDate(newValue)}
              slotProps={{
                textField: {
                  variant: 'outlined',
                  size: 'small',
                },
              }}
            />
            <DatePicker
              label="To"
              value={toDate}
              onChange={(newValue: Date | null) => setToDate(newValue)}
              slotProps={{
                textField: {
                  variant: 'outlined',
                  size: 'small',
                },
              }}
            />
          </LocalizationProvider>
        )}
      </Box>

      {/* Add OKR button */}
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setSelectedOKR({
            Goal: '',
            GoalType: '',
            Team: '',
            AssigneeId: '',
            AssigneeName: '',
            TimePeriod: new Date().toISOString().split('T')[0],
            Status: '',
            comments: [],   
            companyId: '',
            companyName: '',
          });
          setOpenModal(true);
        }}
      >
        Add OKR
      </Button>
    </Box>
      
   
            {/* Compute filteredOkrs here */}
            {(() => {
            let filtered = okrs;

              if (filterType === 'Company') {
                filtered = filtered.filter((okr) => okr.GoalType === 'Company');
              } else if (filterType === 'Team') {
                if (selectedTeam) {
                  filtered = filtered.filter((okr) => okr.Team === selectedTeam);
                }
              } else if (filterType === 'Individual') {
                if (selectedUser) {
                  filtered = filtered.filter(
                    (okr) =>
                      okr.AssigneeId === selectedUser ||
                      okr.AssigneeName === selectedUser
                  );
                }
              }
   
              if (timeFilterType === 'Custom' && fromDate && toDate) {
                filtered = filtered.filter((okr) => {
                  const deadline = new Date(okr.TimePeriod);
                  return deadline >= fromDate && deadline <= toDate;
                });
              }
   
              return (
                <MainCardsLayout
                  goalType={filterType}
                  okrs={filtered}
                  selectedTeam={selectedTeam}
                  selectedUser={selectedUser}
                  onOKRClick={handleOKRClick}
                />
              );
            })()}
          </Box>
        )}
        
        {selectedTab === 1 && (
          <Box sx={{ mt: 2 }}>
            <FocusOKR okrs={okrs} />
          </Box>
        )}
   
        {selectedTab === 2 && (
          <Box sx={{ p: 3 }}>
            <PreviousOKR />
          </Box>
        )}
   
        {/* Modal for OKR details */}
        {selectedOKR && (
          <Dialog
            open={openModal}
            onClose={handleCloseModal}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>
              {selectedOKR._id ? 'Edit OKR' : 'Add New OKR'}
            </DialogTitle>
            <DialogContent>
              <TextField
                label="Goal"
                name="Goal"
                value={selectedOKR.Goal}
                onChange={(e) =>
                  setSelectedOKR({
                    ...selectedOKR!,
                    Goal: e.target.value,
                  })
                }
                fullWidth
                sx={{ mb: 2 }}
              />

              {/* Goal Type and Parent Goal only shown for new OKRs */}
  {!selectedOKR._id && (
    <>
      {/* Goal Type Selection */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Goal Type</InputLabel>
        <Select
          value={selectedOKR.GoalType || ''}
          onChange={(e) =>
            setSelectedOKR({
              ...selectedOKR!,
              GoalType: e.target.value as string,
              Team: e.target.value !== 'Team' ? '' : selectedOKR.Team,
            })
          }
          label="Goal Type"
        >
          <MenuItem value="Company">Company</MenuItem>
          <MenuItem value="Team">Team</MenuItem>
          <MenuItem value="Personal">Personal</MenuItem>
        </Select>
      </FormControl>

      {/* Parent Goal Selection - Only show for Team and Personal goals in new OKRs */}
      {selectedOKR.GoalType && selectedOKR.GoalType !== 'Company' && (
        <FormControl fullWidth sx={{ mb: 2 }}>
         <InputLabel>Parent Goal</InputLabel>
        <Select
            value={selectedOKR.ParentGoalId || 'no_parent_goal'}
            onChange={(e) =>
              setSelectedOKR({
                ...selectedOKR!,
                ParentGoalId: e.target.value === 'no_parent_goal' ? '' : e.target.value,
              })
            }
            label="Parent Goal"
          >
            <MenuItem value="no_parent_goal">No Parent Goal</MenuItem>
            {companyOkrs.map((companyOkr) => (
        <MenuItem key={companyOkr._id} value={companyOkr._id}>
          {`Company: ${companyOkr.Goal}`}
        </MenuItem>
      ))}
          {teamOkrs.map((teamOkr) => (
            <MenuItem key={teamOkr._id} value={teamOkr._id}>
              {`Team: ${teamOkr.Goal}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
    </>
  )}

   
              {/* Owner Selection */}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Owner</InputLabel>
                <Select
                  value={selectedOKR.AssigneeId || ''}
                  onChange={(e) => {
                    const selectedUser = users.find(
                      (user) => user.assigneeId === e.target.value
                    );
                    setSelectedOKR({
                      ...selectedOKR!,
                      AssigneeId: e.target.value as string,
                      AssigneeName: selectedUser ? selectedUser.name : '',
                    });
                  }}
                  label="Owner"
                >
                  {users.map((user) => (
                    <MenuItem key={user.assigneeId} value={user.assigneeId}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
   
              {/* Team Selection */}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Team</InputLabel>
                <Select
                  value={selectedOKR.Team || ''}
                  onChange={(e) =>
                    setSelectedOKR({
                      ...selectedOKR!,
                      Team: e.target.value as string,
                    })
                  }
                  label="Team"
                >
                  {teams.map((team, index) => (
                    <MenuItem key={index} value={team.name}>
                      {team.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
   
              {/* Status Selection */}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Status</InputLabel>
                <Select
                  value={selectedOKR.Status || ''}
                  onChange={(e) => {
                    setSelectedOKR({
                      ...selectedOKR!,
                      Status: e.target.value,
                      // Add completion/failure dates if needed
                      CompletionDate: e.target.value === 'Completed' ? new Date().toISOString() : undefined,
                      FailureDate: e.target.value === 'Failed' ? new Date().toISOString() : undefined,
                    });
                  }}
                  label="Status"
                >
                  {/* Always show these statuses */}
                  <MenuItem value="Green">Green</MenuItem>
                  <MenuItem value="Amber">Amber</MenuItem>
                  <MenuItem value="Red">Red</MenuItem>
                  
                  {/* Show these only for existing OKRs */}
                  {selectedOKR._id && (
                    [
                      <MenuItem key="completed" value="Completed">Completed</MenuItem>,
                      <MenuItem key="failed" value="Failed">Failed</MenuItem>
                    ]
                  )}
                </Select>
              </FormControl>
   
              {/* Deadline */}
              <TextField
                label="Deadline"
                name="TimePeriod"
                type="date"
                value={
                  selectedOKR.TimePeriod
                    ? new Date(selectedOKR.TimePeriod)
                        .toISOString()
                        .split('T')[0]
                    : ''
                }
                onChange={(e) =>
                  setSelectedOKR({
                    ...selectedOKR!,
                    TimePeriod: e.target.value,
                  })
                }
                fullWidth
                sx={{ mb: 2 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
   
  {/* Comments field */}
  <Box sx={{ mb: 2 }}>
  <Typography variant="subtitle1" gutterBottom>
    Comments
  </Typography>

  {/* New Comment Input */}
  <TextField
    label="Add a comment"
    multiline
    rows={3}
    fullWidth
    value={newComment}
    onChange={(e) => setNewComment(e.target.value)}
    sx={{ mb: 2 }}
  />

  {/* Comments History */}
  <Paper variant="outlined" sx={{ maxHeight: 300, overflow: 'auto' }}>
    <List>
      {selectedOKR.comments?.sort((a: Comment, b: Comment) => 
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
      ).map((comment: Comment, index: number) => (
        <ListItem
          key={index}
          divider={index !== (selectedOKR.comments?.length || 0) - 1}
        >
          <Typography variant="body2">
            {comment.text}
            <Box component="span" sx={{ color: 'text.secondary', ml: 1 }}>
              ({format(new Date(comment.timestamp), 'MMM d, yyyy')}, {comment.author})
            </Box>
            {comment.source === 'Linear' && (
              <Chip 
                label="Linear" 
                size="small" 
                color="primary" 
                sx={{ ml: 1 }}
              />
            )}
          </Typography>
        </ListItem>
      ))}
      {(!selectedOKR.comments || selectedOKR.comments.length === 0) && (
        <ListItem>
          <ListItemText secondary="No comments yet" />
        </ListItem>
      )}
    </List>
  </Paper>
</Box>

  {/* Only show Linear section if integration exists */}
  {hasLinearIntegration && (
    <>
      <Divider sx={{ my: 2 }} />
      <Typography variant="subtitle1" gutterBottom>
        Linear Integration
      </Typography>
      
      {selectedOKR?.linearIssueId ? (
        <Card variant="outlined">
          <CardContent>
            <Typography variant="body2" color="textSecondary">
              Linked to Linear issue:{' '}
              <Link 
                href={selectedOKR.linearIssueUrl} 
                target="_blank" 
                rel="noopener noreferrer"
              >
                {selectedOKR.linearIssueTitle}
              </Link>
            </Typography>
          </CardContent>
        </Card>
      ) : (
        <Box>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Link Linear Issue</InputLabel>
            <Select
              value={selectedLinearIssue}
              onChange={(e) => setSelectedLinearIssue(e.target.value)}
              label="Link Linear Issue"
              disabled={loadingLinearIssues}
              onClick={() => {
                if (linearIssues.length === 0) {
                  fetchLinearIssues();
                }
              }}
            >
              {linearIssues.map((issue) => (
                <MenuItem key={issue.id} value={issue.id}>
                  {issue.title} ({issue.state})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <Button
            variant="outlined"
            startIcon={<LinkedInIcon />}
            onClick={handleLinkLinearIssue}
            disabled={!selectedLinearIssue}
            fullWidth
          >
            Link to Linear Issue
          </Button>
        </Box>
      )}
    </>
  )}
</DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal}>Cancel</Button>
              <Button onClick={handleSaveOKR} color="primary" variant="contained">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Box>
    );
};

export default Dashboard;
